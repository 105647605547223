import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "新卒エンジニアが 2 年目でプロジェクトリーダーを経験して得た学び",
  "date": "2021-09-01T09:03:51.000Z",
  "slug": "entry/2021/09/01/180351",
  "tags": ["medley"],
  "hero": "./2021_09_01.png",
  "heroAlt": "社内システムリニューアル"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`はじめまして。メドレーのエンジニア熊本です。新卒で入社し今年で 3 年目になりまして、`}<a parentName="p" {...{
        "href": "/entry/2019/12/16/165947"
      }}>{`2019 年度エンジニア新卒の研修`}</a>{`を終えてから早 2 年が経とうとしています。`}</p>
    <p>{`そんな私ですが去年の 11 月頃から先月までの間、とあるプロジェクトのリーダーを任せてもらっていたので、そのお話をさせていただきます。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`私は新卒研修を終えてから医療介護求人サイト`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`のチームで開発をしていましたが、そのジョブメドレーを支える社内管理システムのリニューアルプロジェクトに初期から携わっていました。`}</p>
    <p>{`こちらのプロジェクトにつきましては、弊社デザイナーの酒井が`}<a parentName="p" {...{
        "href": "/entry/2020/06/19/194558"
      }}>{`デザイナーがデザインツールを使わずに、React を使ってデザインした話`}</a>{`を、弊社エンジニアの山田が`}<a parentName="p" {...{
        "href": "/entry/2020/11/06/180208"
      }}>{`GraphQL, TypeScript, React を用いて型安全に社内システムをリニューアルした話`}</a>{`を以前ブログにしていますので、よろしければあわせてご覧ください。`}</p>
    <p>{`その社内管理システムをどのような流れでリニューアルし、その中で自分の役割がどう変化しどう対応したのかなどについて、次の章からお話ししていきます。`}</p>
    <h1>{`プロジェクトについて`}</h1>
    <p>{`リニューアルの背景やシステムの概要については上に紹介した記事でも説明しているため割愛しますが、求職者や求人を掲載する顧客に関する業務を行っているシステムをおよそ 1 年半かけて刷新するという大きなプロジェクトでした。`}</p>
    <p>{`システムの中でも求職者関連を「Phase1」、顧客関連を「Phase2」として分割し、リニューアルを進めました。`}</p>
    <h1>{`プロジェクト内での自分の役割の変遷`}</h1>
    <p>{`Phase1 の最初期は先輩方がアーキテクチャの設計やスケジューリングをしていました。当時まだ新卒 1 年目で未熟な私でしたが、権限管理のテーブル設計をするタスクをアサインしてもらいました。ここでは詳細を省きますが、初めてのテーブル設計で右も左も分からない状態から責任感を持って何とか形にすることができ、（もちろんリニューアル中に多少の見直しはありましたが）大きな達成感を得たことを覚えています。`}</p>
    <p>{`各種設計、技術選定、開発の進め方などが大方固まり本格的に開発が始まるわけですが、Phase1 の際は先輩社員がプロジェクトリーダーとして引っ張っていただき、自分は開発メンバーの一員として API の作成などに奮闘していました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://graphql.org/"
      }}>{`GraphQL`}</a>{`といった技術やスケジュールが厳密に引かれたプロジェクトでの開発など初めて経験することも多々ありましたが、先輩方にサポートをいただいたり、同期と切磋琢磨しながら取り組めたおかげで、Phase1 を乗り切ることができました。`}</p>
    <p>{`さて、ここからが本題になりますが、Phase2 になるとプロジェクトメンバーの入れ替えや私自身の目標設定も重なり、プロジェクトリーダーを任せてもらうことになります。まずはプロジェクトリーダーに任命されてから、どういった仕事をしていたのかご紹介します。`}</p>
    <h1>{`プロジェクトリーダーの仕事`}</h1>
    <p>{`プロジェクトリーダーとして期待されていたことは以下の通りです。`}</p>
    <ul>
      <li parentName="ul">{`プロジェクト管理`}</li>
      <li parentName="ul">{`システム設計`}</li>
      <li parentName="ul">{`開発`}</li>
      <li parentName="ul">{`チームマネジメント`}</li>
    </ul>
    <p>{`これを更に細分化し、私の実業務と照らし合わせながら並べてみると、多少粒度にばらつきがあるかもしれませんが以下のようなことが挙げられます。`}</p>
    <ol>
      <li parentName="ol">{`要件定義・画面設計（ディレクターとデザイナー主導で進めつつ、エンジニアも実データや既存ロジックを踏まえた観点を持ち合わせて参加しました）`}</li>
      <li parentName="ol">{`開発方針の検討`}</li>
      <li parentName="ol">{`開発タスクへの落とし込み`}</li>
      <li parentName="ol">{`技術調査・選定`}</li>
      <li parentName="ol">{`API 設計`}</li>
      <li parentName="ol">{`工数算出・スケジューリング`}</li>
      <li parentName="ol">{`実装・レビュー`}</li>
      <li parentName="ol">{`QA（Quality Assurance）テスト`}</li>
      <li parentName="ol">{`リリースマネジメント`}</li>
    </ol>
    <p>{`Phase2 は段階的にリリースを行ったため、その度に 1 から 9 までを繰り返していたような流れになります。また、上記に加え、定例ミーティングでの報告や開発メンバーのタスクマネジメントも随時行っていました。`}</p>
    <p>{`もちろん苦労したことは多く、全部を挙げようとするとキリがないのですが、その中でもいくつかに絞った上で紹介したいと思います。`}</p>
    <h1>{`苦労と工夫`}</h1>
    <h2>{`1. 「そもそも何をやればいいのか」`}</h2>
    <p>{`まず最初に苦労したことは「そもそも何をやればいいのかわからない」ということでした。初めから先ほど挙げたような動きをイメージできていたわけではなく、記事や本を読み漁ったり先輩との 1on1 で質問攻めにしたりと基本的な知識を叩き込むわけですが、実際にとった最初の動きとしては「できる部分を見つけてやっていく」ということだったと思います。
自分がリーダーに任命された時点でのプロジェクトの状況としては要件定義や画面設計が進んでいる最中でしたが、これらがまとまるのを待つのではなく「全部決まらないとやれないこと」と「現時点でやれること」を切り分けて動きました。こうしたところから少しずつリズムを作り、最終的に先ほど列挙したような一通りのことがイメージ・実行できるようになったのだと思います。`}</p>
    <h2>{`2. 工数見積もり`}</h2>
    <p>{`一般的に工数見積もりに関する記事は世の中に多く存在しますが、私の場合は工数見積もりの方法がわからなかったというよりも、「どういう思想で見積もったのか、どういう選択肢があるのか」を曖昧にしていたことが当初の問題でした。`}</p>
    <p>{`初めて見積もった時は単に開発タスクを積み上げた工数を報告して満足してしまいましたが、様々な方のフィードバックを受けプロダクト価値を高めるためにどういう動きができるのかを考える必要があったことを痛感しました。単純に工数を積み上げる場合や事業的な都合を踏まえてミニマムで開発する場合など、いくつかの選択肢をそれぞれの軸で考える必要があったことを学びました（この時期は夜な夜な夢の中で工数見積もりをしていたのも今ではいい思い出です）。`}</p>
    <h2>{`3. 意思決定`}</h2>
    <p>{`これはいつになっても正解が存在する類のものではないのですが、特に意思決定には苦労しました。意思決定といっても開発方針から技術選定まで様々な粒度のものがありますが、特に最初から苦労したのは技術的な決定でした。`}</p>
    <p>{`それまで先輩に頼ることの多かった私がプロジェクトリーダーになった直後から何もかもできるようになるわけではないことは明々白々ですが、「自分が決めないと」と焦ってしまっていた時期もあったと思います。`}</p>
    <p>{`そこで一度立ち止まって意識したことは、「何ができて何ができないのかを他者に明示する」ことでした。はっきりと自分に足りていないことを他者に伝えることで、周りもサポートしやすくなると思いますし、自分自身なにがやれることなのか明確になるので単純なことですが効果的であったと思います。他にも開発メンバーの提案で、インセプションデッキを取り入れてみたことも効果的でした。`}</p>
    <p>{`また、意思決定とは文脈が少し変わってきますが、モブプロやペアプロを実施してチーム力を高め属人化をなくしつつ開発効率を向上させる取り組みも、時間が経てば経つほど効果を実感できて良かったと思います。このようにアジャイル開発の手法からチームにフィットする手法をいくつか取り入れることもできました。`}</p>
    <h1>{`プロジェクトを通して成長したこと`}</h1>
    <p>{`これまで小出しで色々とお話しさせていただきましたが、自分が特に成長したと感じていることをまとめさせていただきます。`}</p>
    <h2>{`一通りの経験を通して得られたリード力`}</h2>
    <p>{`「API 設計だけ」ではなく一通り全てを任せていただいたことはとても大きな経験になりました。初めて個人ではなくチーム・プロジェクト全体として効率が良くなる動きを考える経験もできたと思います。`}</p>
    <h2>{`技術力`}</h2>
    <p>{`もちろん実装を通じて得た技術は数えきれないほどありますが、その中でも特に責任を持って他者のコードをレビューしたり、自分が書くコードの影響範囲やスコープを意識し続けたことが大きな糧になっている気がします。`}</p>
    <h2>{`リスク管理力`}</h2>
    <p>{`スケジュール遅延のリスク、方向性がずれてしまうリスク、技術的なリスク、様々ありますがこれらのリスクヘッジを考える力がプロジェクトリーダーには必要です。`}</p>
    <p>{`リスク管理において「先読みが大切」とよく言われますが、私の場合はある先輩社員から「常に 2 週間先を見据えておけ」という具体的な日数のアドバイスをいただきました。具体的にすることであらゆることが想像しやすくなりましたし、それを 1 年以上毎日意識し実行し続けたことが、プロジェクトをやり切ることができた要因にもなっていると思います。もちろんこの言葉は家宝にしようと思っています。`}</p>
    <h2>{`価値に対する視野`}</h2>
    <p>{`何よりも「プロダクトのユーザーに価値を提供すること」の意味を理解しました。ここまでに書いてきたようなスケジュール管理やリスク管理などは、あくまでプロジェクトを遂行する上で必要な仕事の一つでしかないはずです。プロジェクトを通してシステムを使っている社員、更にはその先の顧客・求職者へ如何に価値を提供できるか考えるべきですが、一時期は「どうやるのか・なにをやるのか」というプロジェクト自体を完遂させることしか考えられていない時期もありました。`}</p>
    <p>{`視野が狭くなっていたことに周りからの指摘で気づくことができ、それ以降は「そもそも本当にこの機能はいるのか」などユーザーの立場からの観点も徐々に身に付けることができました。これがきっかけとなり、周りとも頻繁に「なぜやるのか」を議論できるようになったと思います。新卒 1 年目で口酸っぱく言われていた「目的意識」をようやく腹落ちさせ体現することができました。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`最後となりますが、プロジェクトリーダーについて語ってきた私ですが、入社するまでは Web 開発未経験でして、メドレーでの成長を非常に実感しています。そんなメドレーではエンジニア・デザイナーをはじめ多くのポジションで新たなメンバーを募集していますので、少しでもご興味をお持ちいただけた方は、是非お気軽にお話しさせていただければと思います！`}</p>
    <p>{`ここまでお付き合いいただき、ありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      